declare var CONFIG: {
  rum_token: string;
  rum_environment: string;
  pendo_subscription_id: string;
  google_api_key: string;
};

export const RUM_TOKEN = CONFIG.rum_token;
export const RUM_ENVIRONMENT = CONFIG.rum_environment;
export const PENDO_SUBSCRIPTION_ID = CONFIG.pendo_subscription_id;
export const GOOGLE_API_KEY = CONFIG.google_api_key;
